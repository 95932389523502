@media screen and (max-width: 400px) {
  #operators {
    padding: 20px;
    /* width: 111%; */
  }

  #header,
  #about,
  #brokers-owners,
  #testimonials,
  #team,
  #contact,
  #footer {
    /* width: 111%; */
  }

  #portfolio {
    /* width: 110%; */
  }
}